<script>
export default {
  name: "ConfirmModal",
  props:{
    title:{
      type:String,
      default:''
    },
    message:{
      type:String,
      default:''
    },
    status:{
      type:String
    }
  },
  methods:{
    openModal(){
      this.$refs["confirm-modal"].show()
    },
    handleClick(){
      this.$emit('handleSave')
    }
  }
}
</script>

<template>
<b-modal ref="confirm-modal" id="confirm-modal" :title="title" hide-footer>
{{message}}
  <div class="action_button mt-2" >
    <b-button @click="()=>$refs['confirm-modal'].hide()">Cancel</b-button>
    <b-button variant="primary" class="mx-2 " @click="handleClick" > Ok </b-button>
  </div>

</b-modal>
</template>

<style scoped>

</style>
