<script>
export default {
  name: "StatusTicketDisplay",
  props: {
    col: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed:{
    getColorVariant(){
      return this.data.status === 'pending' ? 'warning' : this.data.status ==='accepted' ? 'success' : this.data.status ==='conflict' ? 'danger' : 'default'
    }
  },
  mounted() {
    console.log("this is the pending statut", this.data)
  }
}
</script>

<template>
  <h6>
    <b-badge :variant="getColorVariant" pill  size="sm">
      {{ data.status }}
    </b-badge>
  </h6>
</template>

<style scoped>

</style>
