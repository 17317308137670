<script>
import {BSidebar} from "bootstrap-vue";
import {getDateFormat} from "../../../../libs/utils";
import {formatDate} from "@core/utils/filter";

export default {
  name: "DetailsIssue",
  methods: {
    formatDate, getDateFormat, handleClick(value) {
        this.$emit('handleStatusIssue', {type:value,ticket:this.detailsData.ticket.ticket_name,ticket_id:this.detailsData.ticket.ticket_id})
    }
  },
  components: {
    BSidebar
  },
  props: {
    visible: {type: Boolean, default: () => false},
    detailsData: {
      type: Object,
      default: () => {
      }
    }
  },


}
</script>

<template>
  <b-sidebar
    id="sidebar-backdrop"
    no-header
    backdrop-variant="white"
    backdrop
    right
    width="450px"
    shadow
    v-model="visible"
  >
    <template #default="{ hide }">
      <div
        :class="['p-2 d-flex justify-content-between', detailsData.status ==='conflict'  ? 'bg-primary' : detailsData.status === 'pending' ? 'bg-warning' : 'bg-success']">
        <h4 class="text-white font-weight-bolder" id="sidebar-no-header-title">
          {{ detailsData.ticket.ticket_name }}
        </h4>
        <feather-icon @click="()=>{
          hide()
          this.$emit('closeEvent')
        }" class="cursor-pointer" icon="XCircleIcon"/>
      </div>

      <div class="bg-black p-1 d-flex" style="max-height: 150px;">
        <p class="text-white">
          {{ detailsData.comment }}
        </p>
      </div>
      <div class=" p-1">
        <div>
          <h5 class="my-4">
            <strong>Signed by :</strong>
            {{ detailsData.signal_by.user_lastname + '  ' + detailsData.signal_by.user_firstname }}
          </h5>
          <h5 class="my-4">
            <strong>For user :</strong>
            {{ detailsData.for_user.user_lastname + '  ' + detailsData.for_user.user_firstname }}
          </h5>
          <h5 class="my-4"><strong>Date signed : </strong>{{ formatDate(detailsData.date_added) }} </h5>
          <h5 class="my-4">
            <strong> Error Board:</strong> {{ detailsData.error_board.board_name }}
          </h5>
          <h5 class="my-4">
            <strong>Detected issue Board :</strong> {{ detailsData.current_board.board_name }}
          </h5>
          <h5>
            <strong>Detected issue Column :</strong> {{ detailsData.current_column.column_name }}
          </h5>
          <h5 class="my-4">
            <strong>Error Column :</strong> {{ detailsData.error_column.column_name }}
          </h5>
        </div>
        <div>
          <h5 class="my-4">
            <strong>Team Signed error :</strong> {{ detailsData.current_team.team_name }}
          </h5>
          <h5 class="my-4">
            <strong>Team error :</strong> {{ detailsData.error_team.team_name }}
          </h5>
        </div>
      </div>
    </template>
    <template #footer="{ hide }" v-if="detailsData.status === 'pending' || detailsData.status ==='conflict'">
      <div class="d-flex bg-dark text-light align-items-center justify-content-center px-3 py-2">
        <b-button variant="success" @click="handleClick('accepted')">
          Confirm the error
        </b-button>
        <b-button class="mx-2" variant="danger" @click="handleClick('rejected')">
          Reject the error
        </b-button>
      </div>
    </template>

  </b-sidebar>
</template>

<style scoped>

</style>
