var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-backdrop","no-header":"","backdrop-variant":"white","backdrop":"","right":"","width":"450px","shadow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var this$1 = this;
var hide = ref.hide;
return [_c('div',{class:['p-2 d-flex justify-content-between', _vm.detailsData.status ==='conflict'  ? 'bg-primary' : _vm.detailsData.status === 'pending' ? 'bg-warning' : 'bg-success']},[_c('h4',{staticClass:"text-white font-weight-bolder",attrs:{"id":"sidebar-no-header-title"}},[_vm._v(" "+_vm._s(_vm.detailsData.ticket.ticket_name)+" ")]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XCircleIcon"},on:{"click":function (){
        hide()
        this$1.$emit('closeEvent')
      }}})],1),_c('div',{staticClass:"bg-black p-1 d-flex",staticStyle:{"max-height":"150px"}},[_c('p',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.detailsData.comment)+" ")])]),_c('div',{staticClass:" p-1"},[_c('div',[_c('h5',{staticClass:"my-4"},[_c('strong',[_vm._v("Signed by :")]),_vm._v(" "+_vm._s(_vm.detailsData.signal_by.user_lastname + ' ' + _vm.detailsData.signal_by.user_firstname)+" ")]),_c('h5',{staticClass:"my-4"},[_c('strong',[_vm._v("For user :")]),_vm._v(" "+_vm._s(_vm.detailsData.for_user.user_lastname + ' ' + _vm.detailsData.for_user.user_firstname)+" ")]),_c('h5',{staticClass:"my-4"},[_c('strong',[_vm._v("Date signed : ")]),_vm._v(_vm._s(_vm.formatDate(_vm.detailsData.date_added))+" ")]),_c('h5',{staticClass:"my-4"},[_c('strong',[_vm._v(" Error Board:")]),_vm._v(" "+_vm._s(_vm.detailsData.error_board.board_name)+" ")]),_c('h5',{staticClass:"my-4"},[_c('strong',[_vm._v("Detected issue Board :")]),_vm._v(" "+_vm._s(_vm.detailsData.current_board.board_name)+" ")]),_c('h5',[_c('strong',[_vm._v("Detected issue Column :")]),_vm._v(" "+_vm._s(_vm.detailsData.current_column.column_name)+" ")]),_c('h5',{staticClass:"my-4"},[_c('strong',[_vm._v("Error Column :")]),_vm._v(" "+_vm._s(_vm.detailsData.error_column.column_name)+" ")])]),_c('div',[_c('h5',{staticClass:"my-4"},[_c('strong',[_vm._v("Team Signed error :")]),_vm._v(" "+_vm._s(_vm.detailsData.current_team.team_name)+" ")]),_c('h5',{staticClass:"my-4"},[_c('strong',[_vm._v("Team error :")]),_vm._v(" "+_vm._s(_vm.detailsData.error_team.team_name)+" ")])])])]}},(_vm.detailsData.status === 'pending' || _vm.detailsData.status ==='conflict')?{key:"footer",fn:function(ref){
      var hide = ref.hide;
return [_c('div',{staticClass:"d-flex bg-dark text-light align-items-center justify-content-center px-3 py-2"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.handleClick('accepted')}}},[_vm._v(" Confirm the error ")]),_c('b-button',{staticClass:"mx-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.handleClick('rejected')}}},[_vm._v(" Reject the error ")])],1)]}}:null],null,true),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}})}
var staticRenderFns = []

export { render, staticRenderFns }