<script>
import ticket from "@/table/tables/ticket";

export default {
  name: "TicketCardStatics",
  props: {
    ticket: {
      type: Object,
    },
    ticketCardArray: {
      type: Object,
    },
    totalsIssue: {
      type: Number,
      default: () => 0
    }
  },
  data: () => {
    return {
      title: "",
      number_issues:0
    }
  },
  mounted() {
    this.title = this.ticket.title
    this.number_issues = this.ticket.number_issue
  }

}
</script>

<template>
  <div>
    <h6 style="font-size: 12px">
      {{ ticket.type }}
    </h6>
    <b-card style="min-height: 250px">
      <b-card-text class="h-100" >
        <div class="d-flex flex-column" >
          <div class="d-flex align-items-center ">
            <div>
              <h2>
                {{title}}
              </h2>
            </div>
            <FeatherIcon icon="ChevronDownIcon" style="margin-left: 20px" />
          </div>
          <div class="d-flex align-items-center justify-content-center flex-grow-1 pt-5">
          <span class="font-weight-bolder" style="font-size: 10rem" >
            {{number_issues}}
          </span>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<style scoped lan>

</style>
