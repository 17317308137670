<script>
import DataTable from "@/views/app/CustomComponents/DataTable/DataTable.vue";
import TicketNameCol from "@/views/app/Ticket/widgets/TicketNameCol.vue";
import StatusTicketDisplay from "@/views/app/Ticket/widgets/StatusTicketDisplay.vue";
import DetailsIssue from "@/views/app/Ticket/widgets/DetailsIssue.vue";
import TicketCard from "@/views/app/CustomComponents/TicketCard.vue";
import TicketCardStatics from "@/views/app/Ticket/widgets/TicketCardStatics.vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import NoData from "@/views/app/CustomComponents/NoData/NoData.vue";
import DatePicker from "vue2-datepicker";
import moment from 'moment'
import {BCardBody} from "bootstrap-vue";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default {
  name: "TicketListIssue",
  components: {
    ConfirmModal,
    NoData, vSelect, VueApexCharts, TicketCardStatics, TicketCard, DetailsIssue, DataTable,DatePicker,BCardBody},
  data: () => {
    return {
      listingData: [],
      cardDataIssuesStat:[],
      boardData:[],
      errorDetails: null,
      initialData: null,
      time3:new Date(2024, 0, 1),
      filterDonnut: '',
      graphicIssueTitle:'Year',
      modePicker:'year',
      boardUrl:'/tickets/kanban/user',
      graphic_error_url:'statistics/ticket-errors/graphics',
      loading:false,
      card_error_url:'statistics/ticket-errors/cards',
      ticket_resolve:'tickets/resolve-ticket',
      board_url:'',
      title:'',
      message:'',
      pieChart: {
        series: [14, 12, 16],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [  '#4dff65', '#FF902A','#da172e'],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              size: '100%',
              donut: {
                labels: {
                  total: {
                    show: false,
                    fontSize: '1rem',
                    label: 'Summary',
                    formatter() {
                      return '100%'
                    },
                  },
                },
              },
            },
          },
          labels: ['Issues Pending', 'Issues Accepted', 'Conflict Issues'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      areaChart:{
        series:[{
          name: 'Pending',
          data: []
        }, {
          name: 'Accepted',
          data: []
        },{
          name: 'Conflict',
          data: []
        }],
        chartOptions: {
          chart: {
            height: 'auto',
            type: 'area'
          },
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            categories:['Jan','Fevrier','Mars','Avril','Mai','Juin','Juillet','Aout','Septembre','Octobre','Novembre','Decembre']
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },
          },
        },
      },
      urlList: 'tickets/errors',
      cols: [
        {
          key: 'ticket_name',
          header: {
            name: 'ticket_name',
          },
          props: {
            style: 'min-width: 320px;',
          },
        },
        {
          key: 'signal_by',
          header: {
            name: 'Signal by',
          },
        },
        {
          key: 'board_name',
          header: {
            name: 'Error board name',
          },
        },
        {
          key: 'column_name',
          header: {
            name: 'Error column name',
          },
        },
        {
          key: 'status',
          header: {
            name: 'status',
          },
          component: StatusTicketDisplay
        },
      ],
    }
  },
  watch:{
    time3(newValue,oldValue){
      console.log("this is the new value of the date", newValue)
      let endDate =moment(newValue).endOf('year').format('YYYY-MM-DD')
      console.log("this is the end date", endDate)
    }
  },
  methods: {
    fetchTicketListData() {
      this.$http.get(this.urlList).then((response) => {
        console.log("this i sthe data ", response.data.data)
        if (response.data.data) {
          this.initialData = response.data.data
          this.listingData = response.data.data.map((item) => ({
            ticket_name: item.ticket.ticket_name,
            signal_by: item.signal_by.user_firstname + item.signal_by.user_lastname,
            board_name: item.error_board.board_name,
            column_name: item.error_column.column_name,
            ticket_error_id: item.ticket_error_id,
            status: item.status
          }))
        }
      }).catch((error) => this.$errorToast('And error occured when'))
    },
    handleChangeStatusIssue(value){
      console.log("this is the value")
    },
    handleStatusIssue(value){
      this.title = value.type === 'accepted' ? 'Confirm Issue' : 'Reject Issue'
      this.message = value.type === 'accepted' ? 'Are you sure you want to accept the error on the ticket' + value.ticket : 'Are You sure you want to reject the error on the ticket ' + value.ticket
      this.$refs.confirmIssue.openModal()
    },

    fetchAllBoard(){
      this.$http.get(this.boardUrl).then((response)=>{
        this.boardData.push(response.data.data?.map((item,index)=>({title:item.board_name,number_issue:item.board_id})))
      }).catch((error)=>this.$errorToast("and error occured", error.message))
    },
    fetchGraphicsError(start_date,end_date){
      this.$http.get(this.graphic_error_url + '? start_date='+start_date + '&end_date='+ end_date + '&switch_action='+ this.modePicker.toUpperCase()).then((response)=>{
        this.areaChart.series[0].data = response.data.map((item)=>item.pending)
        this.areaChart.series[1].data = response.data.map((item)=>item.accepted)
        this.areaChart.series[2].data=response.data.map((item)=>item.conflict)
      }).catch((error)=>{
        this.$errorToast("And error occured when trying to get data")
      })
    },
    fetchCardTicketError(){
      this.$http.get(this.card_error_url).then((response)=>{
        this.cardDataIssuesStat = response.data
      }).catch((error)=>this.$errorToast('An error occured'))
    },
    getDetailsData(value) {
      this.errorDetails = this.initialData.find((item) => item.ticket_error_id === value.ticket_error_id)
    },
    closeEvent() {
      this.errorDetails = null
    },
    getValueCardIssue(index){
      return index === 0 ? {title:this.cardDataIssuesStat.board.board_name,number_issue:this.cardDataIssuesStat.board.entry_count,type:'Board'} : (index === 1 && this.cardDataIssuesStat.column.length > 0 )? {title:this.cardDataIssuesStat.column?.[0].column_name,number_issue: this.cardDataIssuesStat.column?.[0].entry_count,type:'Column' } :(index === 2 && this.cardDataIssuesStat.team.length > 0) ? {title: this.cardDataIssuesStat.team?.[0].team_name,number_issue: this.cardDataIssuesStat.team?.[0].entry_count,type:'Team'} : (index === 3 && this.cardDataIssuesStat.user.length > 0) ? {title:this.cardDataIssuesStat.user?.[0].user_firstname + ' ' +  this.cardDataIssuesStat.user?.[0].user_lastname,number_issue: this.cardDataIssuesStat.user?.[0].entry_count,type:'User'} : {}
    },

    getValueArray(value){
      return value === 'Board' ? this.boardData : value === 'Column' ? this.cardDataIssuesStat?.column?.map((item)=>({title:item.column_name,number_issue:item.entry_count})) :value ==='Team' ? this.cardDataIssuesStat?.team?.map((team) =>({title:team.team_name,number_issue:team.entry_count})) : value ==='User' ? this.cardDataIssuesStat?.user?.map((user)=>({title:user.user_firstname + '' + user.user_lastname, number_issue:user.entry_count})) : []
    },
    getType(index){
      return index === 0 ? 'Board' : index === 1 ? 'Column' : index === 2 ? 'Team' : index === 3 ? 'User': ''
    },

  },
  mounted() {
    this.fetchTicketListData()
    this.fetchCardTicketError()
    this.fetchAllBoard()
    this.fetchGraphicsError(moment(new Date()).startOf('year').format('YYYY-MM-DD'),moment(new Date()).endOf('year').format('YYYY-MM-DD'))
  }
}
</script>

<template>
  <div>
    <div v-if="cardDataIssuesStat.board.board_name !==''">
      <b-row>
        <b-col v-for="(ticket,index) in (Object.keys(cardDataIssuesStat).length - 1) " :key="index">
          <b-overlay :show="loading">
            <TicketCardStatics :key="index" :ticket="getValueCardIssue(index)" :totals-issue="cardDataIssuesStat.total_ticket_errors" :ticket-card-array="{type:getType(index),arrayData:getValueArray(getType(index))}"  />
          </b-overlay>
        </b-col>
      </b-row>
      <b-card style="max-height: 50vh" >
          <template #header>
            <div class="d-flex w-100">
              <div class="header-title flex-grow-1">
                <span class="text-bold text-large text-uppercase" style="font-weight: bold">
                  Total issue per  {{graphicIssueTitle}}
                </span>
              </div>
              <div >
                <b-dropdown
                  split
                  split-variant="outline-primary"
                  variant="primary"
                  :text="modePicker.toUpperCase()"
                  class="m-2"
                >
                  <b-dropdown-item v-for="(item,index) in ['year','month','day']" :key="index" @click="()=>modePicker = item" >{{ item }}</b-dropdown-item>
                </b-dropdown>
                <date-picker placeholder="Filter by date" v-model:value="time3" :range="false" :type="modePicker"  ></date-picker>
              </div>
            </div>
          </template>
       <b-card-body>
         <vue-apex-charts
           type="area"
           height="350"
           :series="areaChart.series"
           :options="areaChart.chartOptions"
         />
       </b-card-body>
      </b-card>
      <b-row>
        <b-col cols="4">
          <b-card>
            <b-row class="mb-5">
              <b-col cols="6">
                <v-select
                  placeholder="Select the start column"
                  :clearable="true"
                  :options=" []" transition="" label="label"
                  class="w-100" :reduce="i => i.value.toString()"/>
              </b-col>
              <b-col cols="6">
                <v-select
                  placeholder="Select the start column"
                  :clearable="true"
                  :options=" []" transition="" label="label"
                  class="w-100" :reduce="i => i.value.toString()"/>
              </b-col>
            </b-row>
            <vue-apex-charts
              type="pie"
              height="auto"
              class="donut_class"
              :series="pieChart.series"
              :options="pieChart.chartOptions"
            />
          </b-card>
        </b-col>
        <b-col cols="8">
          <b-overlay :show="listingData.length ===0">
            <data-table
              ref="dataTable"
              :columns="cols"
              :data="listingData"
              :onDetailsClick="(value) =>getDetailsData(value)"
              :bar-actions="[
        {
          icon: 'FilterIcon',
          onClick: () => $refs.filter.openModal(),
        }
      ]"
            />
          </b-overlay>
        </b-col>
      </b-row>
      <DetailsIssue :visible="errorDetails !== null" :details-data="errorDetails" @close-event="closeEvent" @handleStatusIssue="handleStatusIssue"/>
    </div>
    <div  v-else class="h-100 w-100 border-2 ">
      <NoData></NoData>
    </div>
<ConfirmModal :title="title" :message="message" ref="confirmIssue" @handleSave="handleChangeStatusIssue"></ConfirmModal>
  </div>
</template>

<style lang="scss">
.donut_class {
  min-height: 600px !important;
}
</style>
